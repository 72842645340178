import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {createClient} from 'contentful'

export const useContentfulClient = () => {
    const {contentfulSpace, contentfulEnvironment, contentfulAccessToken} =
        getConfig()

    return createClient({
        space: contentfulSpace,
        environment: contentfulEnvironment,
        accessToken: contentfulAccessToken
    })
}
