import PropTypes from 'prop-types'
import React from 'react'

import {
    Flex,
    Heading,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {colors, styleConfigChakraKeys} from '../../constants'
import Link from '../link'

const CTASection = ({heading, ctaItems}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.heroSection)

    return (
        <Flex
            flexDirection="column"
            alignItems="left"
            {...styles.ctaSectionContainer}
        >
            {/* <Skeleton isLoaded={heading}> */}
            <Heading
                fontFamily="Raleway"
                fontWeight={300}
                fontSize={{base: '40px', xl: '64px'}}
            >
                {heading}
            </Heading>
            {/* </Skeleton> */}
            <Flex
                flexDirection="column"
                alignItems="center"
                width="100%"
                gap="8px"
            >
                {ctaItems?.map((cta, index) => {
                    const _cta = cta?.fields ?? cta
                    return (
                        <Link
                            {..._cta}
                            key={index}
                            buttonStyles={{...styles.button}}
                            _hover={{
                                svg: {opacity: '1'},
                                background: colors.chipGray,
                                backgroundColor: colors.chipGray,
                                borderColor: colors.graySolid
                            }}
                            padding={{base: '19px 25px', lg: '18px 42px'}}
                            iconStyles={{
                                opacity: '0',
                                _hover: {opacity: '1'},
                                boxSize: '47px'
                            }}
                        />
                    )
                })}
            </Flex>
        </Flex>
    )
}

CTASection.propTypes = {
    ctaItems: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string
        })
    ),
    heading: PropTypes.string
}

export default CTASection
