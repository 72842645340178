import {
    DEFAULT_LIMIT_VALUES,
    DEFAULT_SEARCH_PARAMS
} from '@salesforce/retail-react-app/app/constants'

// original value is 25
DEFAULT_LIMIT_VALUES[0] = 3
DEFAULT_SEARCH_PARAMS.limit = 3

export const DEFAULT_LOCALE = 'en-CA'
export const DEFAULT_SITE_TITLE = 'Rennaï'

export const SHIPPING_COUNTRY_CODES = [{value: 'CA', label: 'Canada'}]

export const CUSTOM_HOME_TITLE =
    'Rennaï: Your Self-Care & Beauty Boutique in Montréal, Canada'

export {DEFAULT_LIMIT_VALUES, DEFAULT_SEARCH_PARAMS}

export * from '@salesforce/retail-react-app/app/constants'

export * from './constants/section-types'
export * from './constants/colors'
export * from './constants/event-section.constants'
export * from './constants/form.constants'
export * from './constants/icons'
export * from './constants/language-switcher.constants'
export * from './constants/local-storage.constants'
export * from './constants/newsletter.constants'
export * from './constants/react-query-queries-list'
export * from './constants/style-config-chakra-keys'
export * from './constants/template-pages'
export * from './constants/ingredient-brand.constant'
export * from './constants/account-constants'
