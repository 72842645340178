export const SectionTypes = {
    carousel: 'carousel',
    contentGrid: 'contentGrid',
    homeHeroSection: 'homeHeroSection',
    product: 'product',
    serviceCard: 'serviceCard',
    textAsset: 'textAsset',
}

export const ContentGridSectionTypes = {
    body: 'Body',
    bookingSection: 'Booking Section',
    categoryGrid: 'Category Grid',
    description: 'Description',
    heroCarousel: 'Hero Carousel',
    heroSection: 'Hero Section',
    ingredientGlossary: 'Ingredient Glossary',
    newsletterSection: 'Newsletter Section',
    paragraph: 'Paragraph',
    pinnedSection: 'Pinned Section',
    productCarousel: 'Product Carousel',
    realms: 'Realms',
    videoSection: 'Video Section',
}

export const ContentGridItemTypes = {
    card: 'card',
    richTextContent: 'richTextContent'
}


















