import {useEffect, useState} from 'react'
import {useContentfulClient} from './use-contentful-client.hook'
import {useIntl} from 'react-intl'

export const useContentfulPageBySlug = (slug) => {
    const {locale} = useIntl()
    const [data, setData] = useState(null)
    const contentfulClient = useContentfulClient()

    useEffect(() => {
        contentfulClient
            .getEntries({
                'fields.slug': slug,
                locale,
                content_type: 'page',
                include: 10
            })
            .then((response) => {
                const item = (response?.items ?? [])?.at(-1)?.fields ?? null
                if (item)
                    setData({
                        ...item,
                        sections: item?.sections ?? []
                    })
            })
            .catch((error) => console.error('>>>> error', error))
    }, [])

    return {data}
}
