import React from 'react'
import {useLocation} from 'react-router-dom'
import Footer from '.'

const FooterWrapper = () => {
    const location = useLocation()
    if (location.pathname.includes('/store/registration')) return <></>
    return <Footer />
}

export default FooterWrapper
