import {useEffect, useState} from 'react'
import {useContentfulClient} from './use-contentful-client.hook'
import {useSearchParams} from '@salesforce/retail-react-app/app/hooks'

export const useContentfulEntriesBlogs = (locale) => {
    const [data, setData] = useState(null)
    const contentfulClient = useContentfulClient()
    const [searchParams] = useSearchParams()
    const offset = searchParams?.offset ?? 0
    const itemsPerpage = 12

    useEffect(() => {
        contentfulClient
            .getEntries({
                content_type: 'article',
                'fields.hideFromArticlesPage': false,
                limit: itemsPerpage,
                locale,
                skip: offset
            })
            .then((response) => {
                const total = response?.total ?? 1

                setData({
                    ...response,
                    pages: Math.ceil(total / itemsPerpage)
                })
            })
            .catch((error) => console.error('>>>> error', error))
    }, [offset])

    return {data}
}
