import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {useCustomerType} from '@salesforce/commerce-sdk-react'
import {
    Box,
    useBreakpointValue,
    useDisclosure
} from '@salesforce/retail-react-app/app/components/shared/ui'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

import {useGetHeader} from '../../clients/contentful'
import CartDrawer from '../../components/cart-drawer'
import {HOME_HREF} from '../../constants'
import DrawerMenu from '../drawer-menu'
import ListMenu from '../list-menu'
import Header from './index'
import AnnouncementBar from './partials/announcement-bar'

export const HeaderWrapper = ({styles, authModal}) => {
    const [searchShow, setSearchShow] = useState(false)
    const {data: header} = useGetHeader()
    const history = useHistory()
    const location = useLocation()
    const {buildUrl} = useMultiSite()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [enableBurgerAnimation, setEnableBurgerAnimation] = useState(false)
    const {isRegistered} = useCustomerType()
    const announcementBarRef = useRef()
    const [topForPopover, setTopForPopover] = useState(67)
    const [topPaddingForDrawer, setTopPaddingForDrawer] = useState(40)

    const isDesktop = useBreakpointValue({
        base: false,
        xl: true
    })

    const onMenuClick = () => {
        isOpen ? onClose() : onOpen()
        setEnableBurgerAnimation(true)
    }

    const onAccountClick = () => {
        // Link to account page for registered customer, open auth modal otherwise
        if (isRegistered) {
            const path = buildUrl('/account')
            history.push(path)
        } else {
            // if they already are at the login page, do not show login modal
            if (new RegExp(`^/login$`).test(location.pathname)) return
            authModal.onOpen()
        }
    }

    const onLogoClick = () => {
        // Goto the home page.
        const path = buildUrl(HOME_HREF)

        history.push(path)

        // Close the drawer.
        onClose()
    }

    const [isCartDrawerOpen, setIsCartDrawerOpen] = React.useState(false)
    const handleCartDrawerClose = () => {
        setIsCartDrawerOpen(false)
    }
    const handleCartDrawerOpen = () => {
        setIsCartDrawerOpen(true)
    }

    const onCartClick = () => {
        if (location.pathname.match('/cart')) {
            const path = buildUrl('/cart')
            return history.push(path)
        }
        handleCartDrawerOpen()
        // Close the drawer.
        onClose()
    }

    useEffect(() => {
        // Lets automatically close the mobile navigation when the
        // location path is changed.
        onClose()
    }, [location])

    useEffect(() => {
        if (announcementBarRef?.current) {
            setTopForPopover(announcementBarRef.current.offsetHeight + 67)
            setTopPaddingForDrawer(announcementBarRef.current.offsetHeight + 40)
        }
    }, [announcementBarRef.current])

    useEffect(() => {
        if (!isDesktop && isOpen) {
            document.body.classList.add('modal-open')
        } else {
            document.body.classList.remove('modal-open')
        }
    }, [isDesktop, isOpen])

    const activeAnnouncementBar = header?.announcementBar

    if (location.pathname.includes('/store/registration')) return <></>

    return (
        <Box {...styles.headerWrapper} zIndex="1401">
            <>
                {activeAnnouncementBar && (
                    <AnnouncementBar
                        data={activeAnnouncementBar?.fields}
                        ref={announcementBarRef}
                    />
                )}
                <Header
                    onMenuClick={onMenuClick}
                    onCloseMegaNavDrawer={onClose}
                    onLogoClick={onLogoClick}
                    onMyCartClick={onCartClick}
                    onMyAccountClick={onAccountClick}
                    mobileNavOpen={isOpen}
                    enableBurgerAnimation={enableBurgerAnimation}
                    header={header}
                    setSearchShow={setSearchShow}
                    searchShow={searchShow}
                    hasActiveAnnouncementBar={!!activeAnnouncementBar}
                >
                    {!isDesktop && (
                        <DrawerMenu
                            isOpen={isOpen}
                            onClose={onClose}
                            onLogoClick={onLogoClick}
                            header={header}
                            paddingTop={topPaddingForDrawer}
                        />
                    )}

                    {isDesktop && (
                        <ListMenu
                            header={header}
                            setSearchShow={setSearchShow}
                            top={topForPopover}
                        />
                    )}

                    {
                        <CartDrawer
                            isDrawerOpen={isCartDrawerOpen}
                            onDrawerClose={handleCartDrawerClose}
                            type="cart"
                        />
                    }
                </Header>
            </>
        </Box>
    )
}

HeaderWrapper.displayName = 'HeaderWrapper'

HeaderWrapper.propTypes = {
    isCheckout: PropTypes.bool,
    styles: PropTypes.object,
    authModal: PropTypes.object
}
