/* eslint-disable jsx-a11y/no-distracting-elements */
import _ from 'lodash'
import React, {forwardRef} from 'react'

import {
    Box,
    List,
    ListItem
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import Link from '../../link'
import '../../hero-section/hero-carousel.scss'

const AnnouncementBar = forwardRef(({data}, ref) => {
    const announcementItems = (data?.notifications ?? []).map(({fields}) => ({
        ...fields,
        theme: fields?.theme?.fields
    }))

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false
    }

    if (announcementItems?.length == 0) return <></>

    return (
        <Box position="relative" width="100vw" ref={ref}>
            <List>
                <Slider {...settings}>
                    {_.map(announcementItems, (announcementLink, index) => (
                        <div key={index}>
                            <ListItem
                                padding="6px 18px"
                                key={announcementLink?.sys?.id}
                                width="100vw"
                                maxWidth="100vw"
                                backgroundColor={
                                    announcementLink?.theme
                                        ? announcementLink?.theme
                                              .backgroundColor
                                        : '#782B2F'
                                }
                                textAlign="center"
                            >
                                <Link
                                    buttonStyles={{
                                        color: announcementLink?.theme
                                            ? announcementLink?.theme.color
                                            : 'white',
                                        backgroundColor: 'none',
                                        background: 'none',
                                        fontSize: {
                                            base: '10px',
                                            md: '14px'
                                        },
                                        fontWeight: {
                                            base: '400',
                                            md: '500'
                                        },
                                        _hover: {
                                            textDecoration: 'none',
                                            backgroundColor: 'none',
                                            background: 'none'
                                        },
                                        textAlign: 'center',
                                        width: '100vw',
                                        maxWidth: '100vw',
                                        padding: 'none',
                                        height: '100%'
                                    }}
                                    {...announcementLink}
                                    type={announcementLink.type || 'tertiary'}
                                />
                            </ListItem>
                        </div>
                    ))}
                </Slider>
            </List>
        </Box>
    )
})

AnnouncementBar.propTypes = {
    header: PropTypes.object
}

AnnouncementBar.displayName = 'AnnouncementBar'

export default AnnouncementBar
