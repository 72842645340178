import {useEffect, useState} from 'react'
import {useContentfulClient} from './use-contentful-client.hook'
import {useIntl} from 'react-intl'
import {contentfulMediaFormatter} from '../../../utils'

const formatData = ({sys, fields}) => ({
    ...fields,
    sys
})

export const useGetHeader = () => {
    const {locale} = useIntl()
    const [data, setData] = useState(null)
    const contentfulClient = useContentfulClient()

    useEffect(() => {
        contentfulClient
            .getEntries({
                locale,
                content_type: 'header',
                include: 10
            })
            .then((response) => {
                const items = response?.items ?? []
                const header = items?.at(-1)?.fields ?? null

                if (header) {
                    const {left = [], right = [], top = []} = header
                    setData({
                        ...header,
                        left: left.map((item) => formatData(item)),
                        right: right.map((item) => formatData(item)),
                        top: top.map((item) => formatData(item)),
                        logo: contentfulMediaFormatter(header?.logo)
                    })
                }
            })
            .catch((error) => console.error('>>>> error', error))
    }, [])

    return {data}
}
