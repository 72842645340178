
export function contentfulMediaFormatter(media) {
    const mobile = media?.fields?.mobile?.fields?.file
    const desktop = media?.fields?.desktop?.fields?.file

    return {
        mobile: {
            ...mobile,
            url: mobile?.url
                ? `https:${mobile?.url}`
                : null
        },
        desktop: {
            ...desktop,
            url: desktop?.url
                ? `https:${desktop?.url}`
                : null
        }
    }
}
