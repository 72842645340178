import {gql} from 'graphql-request'
import {LINK_FRAGMENT, MEDIA_FRAGMENT, THEME_FRAGMENT} from './fragments'

export const listCollectionQuery = gql`
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    ${THEME_FRAGMENT}
    query ListCollection($locale: String!, $listName: String! = "") {
        listCollection(limit: 1, where: {name: $listName}, locale: $locale) {
            items {
                heading
                headingType
                description
                listType
                nonReferenceItems
                theme {
                    ...ThemeFragment
                }
                itemsCollection {
                    items {
                        ...LinkFragment
                        ... on Card {
                            title
                            subtitle
                            cta {
                                ...LinkFragment
                            }
                            media {
                                ...MediaFragment
                            }
                            description
                            name
                        }
                    }
                }
            }
        }
    }
`
