import {listCollectionQuery} from '../queries/list.query'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useListContentful = (queryKey, {listName} = {}) => {
    const {data, error} = useContentfulQueryWithHook(
        queryKey,
        listCollectionQuery,
        {
            listName: listName || queryKey[0]
        }
    )

    return {data, error}
}
