import React from 'react'

import {
    Box,
    Grid,
    Skeleton
} from '@salesforce/retail-react-app/app/components/shared/ui'
import CategoryCard from './category-card'
import {contentfulMediaFormatter} from '../../utils/contentful'

const CategoryGrid = ({data}) => {
    const dataItems = data?.items ?? []
    const columns = data?.columns?.fields

    if (!dataItems || dataItems?.length === 0)
        return (
            <Box paddingX={{base: '17px', md: '32px'}}>
                <Skeleton width="100%" height="600px"></Skeleton>
                <Grid
                    paddingTop={{base: '60px', md: '40px'}}
                    templateColumns={{
                        base: '1',
                        md: `repeat(3, 1fr)`
                    }}
                    columnGap={{
                        md: '28px',
                        xl: '18px'
                    }}
                    rowGap={{base: '30px', md: '20px'}}
                >
                    {[1, 2, 3, 4, 5, 6].map((index) => {
                        return (
                            <Skeleton
                                key={index}
                                height={{base: '400px', xl: '200px'}}
                            />
                        )
                    })}
                </Grid>
            </Box>
        )

    return (
        <Box
            paddingLeft={{base: '17px', md: '32px', lg: '0'}}
            paddingRight={{base: '17px', md: '36px', lg: '0'}}
        >
            <Grid
                templateColumns={{
                    base: columns?.base,
                    lg: `repeat(${columns?.xl}, 1fr)`
                }}
                columnGap={{
                    base: '18px',
                    lg: '36px',
                    xl: '52px'
                }}
                rowGap={{base: '29px', md: '25px'}}
            >
                {dataItems?.map(({sys, fields}) => (
                    <CategoryCard
                        key={sys?.id}
                        index={sys?.id}
                        data={{
                            ...fields,
                            banner: contentfulMediaFormatter(fields?.banner),
                            theme: fields?.theme?.fields ?? null,
                            cta: fields?.cta?.fields ?? null
                        }}
                        total={dataItems?.length ?? 0}
                    />
                ))}
            </Grid>
        </Box>
    )
}

export default CategoryGrid
